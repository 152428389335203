<template>

  <spinner v-if="loading" />

  <div v-else>
    <div v-if="pageSelectOnTop && totalPages > 0" class="flex mb-betweenElements">
      <div class="flex flex-row ml-auto">
        <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
        <spinner-small-primary v-if="pageLoading" class="min-w-13" />
        <div v-else class="cursor-default min-w-13 text-center">{{offset + 1}} / {{totalPages}}</div>
        <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
      </div>
    </div>

    <slot></slot>

    <div v-if="totalPages > 0" class="flex mt-betweenElements">
      <p class="mr-3">Seitengrösse: {{ totalPages > 1 ? pageSize : 'alle' }} - </p>
      <button v-if="totalPages > 1" class="hover:text-primary transition-color" @click="onLoadMore">Mehr anzeigen</button>
      <div class="flex flex-row ml-auto">
        <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
        <spinner-small-primary v-if="pageLoading" class="min-w-13" />
        <div v-else class="cursor-default min-w-13 text-center">{{offset + 1}} / {{totalPages}}</div>
        <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
      </div>
    </div>
  </div>
  
</template>

<script setup lang="ts">
import BaseIcon from '@/components/base/BaseIcon.vue'
import SpinnerSmallPrimary from '@/components/generel/SpinnerSmallPrimary.vue'
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Spinner from '@/components/generel/Spinner.vue'

const emits = defineEmits(['load-page']);

const props = defineProps<{
  pageSelectOnTop: boolean;
}>();

const route = useRoute();
const router = useRouter();

const loading = ref(true);

let pageSize = !route.query.pageSize ? 10 : +route.query.pageSize;
const totalPages = ref(0);
const offset = ref(!route.query.offset ? 0 : +route.query.offset);
const pageLoading = ref(false);

function onNextPage() {
  if (pageLoading.value || offset.value + 1 >= totalPages.value) return;
  offset.value += 1;

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      offset: offset.value
    }
  });

  pageLoading.value = true;
  emits('load-page', pageSize, offset.value);
}

function onPreviousPage() {
  if (pageLoading.value || offset.value == 0) return;
  offset.value -= 1;

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      offset: offset.value
    }
  });

  pageLoading.value = true;
  emits('load-page', pageSize, offset.value);
}

function onLoadMore() {
  const currentMax = pageSize * totalPages.value;
  pageSize += 10;

  while (currentMax / pageSize < offset.value + 1) offset.value -= 1;

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      pageSize: pageSize
    }
  });
  emits('load-page', pageSize, offset.value);
}

function pageLoaded(pages: number) {
  totalPages.value = pages;
  pageLoading.value = false;
  loading.value = false;
}

function resetValues() {
  offset.value = 0;
  totalPages.value = 1;
  
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      offset: 0
    }
  });
}

function setLoading() {
  loading.value = true;
}

function getPageSize() {
  return pageSize;
}

function getOffset() {
  return offset.value;
}

function getTotalPages() {
  return totalPages.value;
}

function loadPage() {
  emits('load-page', pageSize, offset.value);
}

defineExpose({
  pageLoaded,
  resetValues,
  getPageSize,
  getOffset,
  getTotalPages,
  loadPage,
  setLoading
})

</script>

<style scoped>

</style>